import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/firebase/init";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
// import UserLayout from "../views/Layout/UserLayout.vue";
import AuthLayout from "../views/Layout/AuthBasicLayout.vue";
import BlankLayout from "../views/Layout/BlankLayout.vue";

Vue.use(VueRouter);
// const BlankHome = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/BlankHome.vue");
const CheckAllowedTest = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Auth/CheckAllowedTest.vue"
  );
const NotFound = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/NotFound.vue");
const Registrazione = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Multiprojects/Registrazione.vue"
  );
const EdenredFestaPalazzoGuests = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/EdenredFestaPalazzoGuests.vue"
  );
const People = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/People.vue"
  );
const Live = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/Live.vue"
  );
const Accessi = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Admin/Accessi.vue"
  );
const SetSettings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Admin/SetSettings.vue");

const Login = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Auth/LoginFullScreen.vue"
  );
const LoginAdmin = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/LoginAdmin.vue");
const Confirmation = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/Confirmation.vue");
// const About = () =>
//   import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/About.vue");

function authGuard(to, from, next) {
  const allowedParams = [""];
  // Ottieni il parametro dall'URL
  const param = to.params["project"];
  // console.log(param);

  // Controlla se il parametro  è presente nell'elenco
  if (allowedParams.indexOf(param) === -1) {
    // Nega l'accesso e reindirizza
    next({
      name: 'NotFound',
      params: { requestedPage: to.fullPath }
    });
  } else {
    next();
  }
}
function authGuardReg(to, from, next) {
  const allowedParams = [""];
  // Ottieni il parametro dall'URL
  const param = to.params["project"];
  // console.log(param);

  // Controlla se il parametro  è presente nell'elenco
  if (allowedParams.indexOf(param) === -1) {
    // Nega l'accesso e reindirizza
    next({
      name: 'NotFound',
      params: { requestedPage: to.fullPath }
    });
  } else {
    if (from.name === "Confirmation") {
      next("/milano");
    } else next();
  }
}
function authGuardConfirmed(to, from, next) {
  const allowedParams = [""];
  // Ottieni il parametro dall'URL
  const param = to.params["project"];
  // console.log(param);

  // Controlla se il parametro  è presente nell'elenco
  if (allowedParams.indexOf(param) === -1) {
    // Nega l'accesso e reindirizza
    next({
      name: 'NotFound',
      params: { requestedPage: to.fullPath }
    });
  } else {
    // if (from.name === "Registrazione") {
    //   next();
    // } else next("/milano");
    next();
  }
}

const routes = [
  {
    path: "/",
    component: BlankLayout,
    children: [
      // {
      //   path: "",
      //   name: "BlankHome",
      //   component: BlankHome,
      //   meta: {
      //     groupName: "Authpages",
      //   },
      // },
      {
        path: "",
        name: "BlankHome",
        component: NotFound,
        meta: {
          groupName: "Authpages",
        },
        params: { requestedPage: "/" }
      },
      {
        path: "/notfound",
        name: "NotFound",
        component: NotFound,
        meta: {
          groupName: "Authpages",
        },
      },
      {
        path: "/check",
        name: "CheckAllowedTest",
        component: CheckAllowedTest,
        meta: {
          groupName: "Authpages",
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: "/:project/registrazione",
    component: BlankLayout,
    children: [
      {
        path: "/:project/registrazione",
        name: "Registrazione",
        // redirect: "/:project",
        component: Registrazione,
        beforeEnter: authGuardReg,
        meta: {
          groupName: "Authpages",
        },
      },
    ],
  },
  {
    path: "/:project/admin",
    component: DashboardLayout,
    redirect: "/:project/admin/reports",
    children: [
      {
        path: "/:project/admin/reports",
        name: "AdminPage",
        component: EdenredFestaPalazzoGuests,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
          requiresEditor: true,
        },
      },
      {
        path: "/:project/admin/ospiti",
        name: "Ospiti",
        component: People,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
          requiresAdmin: true,
        },
      },
      {
        path: "/:project/admin/live",
        name: "Live",
        component: Live,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
          requiresEditor: true,
        },
      },
      {
        path: "/:project/admin/accessi",
        name: "Accessi",
        component: Accessi,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
          requiresEditor: true,
        },
      },
      {
        path: "/:project/admin/settings",
        name: "Settings",
        component: SetSettings,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: "/:project/",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
      // {
      //   path: "/:project/about",
      //   name: "About",
      //   component: About,
      //   beforeEnter: authGuard,
      //   meta: {
      //     groupName: "Authpages",
      //   },
      // },
      {
        path: "/:project/adminlogin",
        name: "AdminLogin",
        component: LoginAdmin,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
      {
        path: "/:project/confirmed",
        name: "Confirmation",
        component: Confirmation,
        beforeEnter: authGuardConfirmed,
        meta: {
          groupName: "Authpages",
        },
      },
      {
        path: "/:project/*",
        name: "NotWorking",
        component: NotFound,
        beforeEnter: authGuard,
        meta: {
          groupName: "Authpages",
        },
      },
    ],
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //controllo se la route chiede autenticazione
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    //controllo se user è autenticato
    let user = auth.currentUser;
    if (user) {
      //se user esiste sono autenticato quindi posso andare a next
      return next();
    } else {
      //se falso non sono autenticato quindi vengo reindirizzato a Home
      return next(from);
    }
  } else if (to.matched.some((rec) => rec.meta.requiresAdmin)) {
    // come prima ma qui viene richiesto che user sia anche Admin
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.admin) {
          if (to.name === "Login") {
            //se è admin MA CHIEDE LOGIN deve andare a Reports
            return next({ name: "AdminPage" });
          }
          //se è admin può andare a next
          else return next();
        } else if (idTokenResult.claims.editor) {
          //se è editor deve andare a People
          return next({ name: "AdminPage" });
        } else {
          //redirect a Profilo perché ho già controllato che user esista
          return next({ name: "Login" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresEditor)) {
    // come prima ma qui viene richiesto che user sia editor
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.editor || idTokenResult.claims.admin) {
          //se è admin o editor può andare a next
          if (to.name === "Login") {
            if (idTokenResult.claims.admin) {
              return next({ name: "AdminPage" });
            } else return next({ name: "AdminPage" });
          } else return next();
        } else {
          //redirect a Home perché ho già controllato che user esista
          return next({ name: "Login" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else if (to.matched.some((rec) => rec.meta.requiresFinance)) {
    // come prima ma qui viene richiesto che user sia editor
    let user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        if (
          // (user.email.includes("@adisanto.com")) ||
          (idTokenResult.claims.editor && user.email.includes("@mutika.it")) ||
          idTokenResult.claims.admin
        ) {
          //se è admin o editor mutika può andare a next
          return next();
        } else if (idTokenResult.claims.editor) {
          //se è editor non mutika deve andare a People
          return next({ name: "AdminPage" });
        } else {
          //redirect a Home perché ho già controllato che user esista
          return next({ name: "Home" });
        }
      });
    } else {
      return next({ name: "Login" });
    }
  } else {
    //questa route non chiede autenticazione, ma se user è autenticato non può andare a Login
    let user = auth.currentUser;
    if (user && to.name === "Login") {
      user.getIdTokenResult().then((idTokenResult) => {
        if (idTokenResult.claims.admin) {
          return next({ name: "AdminPage" });
        } else if (idTokenResult.claims.editor) {
          return next({ name: "AdminPage" });
        } else return next({ name: "Home" });
      });
    } else {
      return next();
    }
  }
});
// router.beforeEach((to, from, next) => {
//   //controllo se la route chiede autenticazione
//   if (to.matched.some((rec) => rec.meta.requiresAuth)) {
//     //controllo se user è autenticato
//     let user = auth.currentUser;
//     if (user) {
//       //se user esiste sono autenticato quindi posso andare a next
//       return next();
//     } else {
//       //se falso non sono autenticato quindi vengo reindirizzato a Home
//       return next(from);
//     }
//   } else if (to.matched.some((rec) => rec.meta.requiresAdmin)) {
//     // come prima ma qui viene richiesto che user sia anche Admin
//     let user = auth.currentUser;
//     if (user) {
//       user.getIdTokenResult().then((idTokenResult) => {
//         if (idTokenResult.claims.admin) {
//           if (to.name === "Login") {
//             //se è admin MA CHIEDE LOGIN deve andare a Reports
//             return next({ name: "Reports" });
//           }
//           //se è admin può andare a next
//           else return next();
//         } else if (idTokenResult.claims.editor) {
//           //se è editor deve andare a People
//           return next({ name: "People" });
//         } else {
//           //redirect a Profilo perché ho già controllato che user esista
//           return next({ name: "Home" });
//         }
//       });
//     } else {
//       return next({ name: "Login" });
//     }
//   } else if (to.matched.some((rec) => rec.meta.requiresEditor)) {
//     // come prima ma qui viene richiesto che user sia editor
//     let user = auth.currentUser;
//     if (user) {
//       user.getIdTokenResult().then((idTokenResult) => {
//         if (idTokenResult.claims.editor || idTokenResult.claims.admin) {
//           //se è admin o editor può andare a next
//           if (to.name === "Login") {
//             if (idTokenResult.claims.admin) {
//               return next({ name: "Reports" });
//             } else return next({ name: "People" });
//           } else return next();
//         } else {
//           //redirect a Home perché ho già controllato che user esista
//           return next({ name: "Home" });
//         }
//       });
//     } else {
//       return next({ name: "Login" });
//     }
//   } else if (to.matched.some((rec) => rec.meta.requiresFinance)) {
//     // come prima ma qui viene richiesto che user sia editor
//     let user = auth.currentUser;
//     if (user) {
//       user.getIdTokenResult().then((idTokenResult) => {
//         if (
//           // (user.email.includes("@adisanto.com")) ||
//           (idTokenResult.claims.editor && user.email.includes("@mutika.it")) ||
//           idTokenResult.claims.admin
//         ) {
//           //se è admin o editor mutika può andare a next
//           return next();
//         } else if (idTokenResult.claims.editor) {
//           //se è editor non mutika deve andare a People
//           return next({ name: "People" });
//         } else {
//           //redirect a Home perché ho già controllato che user esista
//           return next({ name: "Home" });
//         }
//       });
//     } else {
//       return next({ name: "Login" });
//     }
//   } else {
//     //questa route non chiede autenticazione, ma se user è autenticato non può andare a Login
//     let user = auth.currentUser;
//     if (user && to.name === "Login") {
//       user.getIdTokenResult().then((idTokenResult) => {
//         if (idTokenResult.claims.admin) {
//           return next({ name: "Reports" });
//         } else if (idTokenResult.claims.editor) {
//           return next({ name: "People" });
//         } else return next({ name: "Home" });
//       });
//     } else {
//       return next();
//     }
//   }
// });

export default router;
