const mailReminderHtml = (nominativo) => {
  const linkManuzio = 'https://www.google.com/maps/place/Parcheggio+Via+Manuzio/@45.4787035,9.2003313,16z/data=!3m1!4b1!4m6!3m5!1s0x4786c6c7d5277349:0x687b2ec4047fb9e7!8m2!3d45.4787035!4d9.2003313!16s%2Fg%2F1233pgvjw?hl=it&entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D';
  const linkMachiavelli = 'https://www.google.com/maps/place/Machiavelli+Parking/@45.4779147,9.1988651,16z/data=!3m1!4b1!4m6!3m5!1s0x4786c6c7b7d43eb3:0xece5908c1d679839!8m2!3d45.477911!4d9.20144!16s%2Fg%2F11b6g9kckn?hl=it&entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D';
  return `<!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    
    <head>
      <title>
      </title>
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <!--<![endif]-->
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <style type="text/css">
        #outlook a {
          padding: 0;
        }
    
        body {
          margin: 0;
          padding: 0;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
        }
    
        table,
        td {
          border-collapse: collapse;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        }
    
        img {
          border: 0;
          height: auto;
          line-height: 100%;
          outline: none;
          text-decoration: none;
          -ms-interpolation-mode: bicubic;
        }
    
        p {
          display: block;
          margin: 13px 0;
        }
      </style>
      <!--[if mso]>
            <noscript>
            <xml>
            <o:OfficeDocumentSettings>
              <o:AllowPNG/>
              <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
            </xml>
            </noscript>
            <![endif]-->
      <!--[if lte mso 11]>
            <style type="text/css">
              .mj-outlook-group-fix { width:100% !important; }
            </style>
            <![endif]-->
      <style type="text/css">
        @media only screen and (min-width:480px) {
          .mj-column-per-100 {
            width: 100% !important;
            max-width: 100%;
          }
        }
      </style>
      <style media="screen and (min-width:480px)">
        .moz-text-html .mj-column-per-100 {
          width: 100% !important;
          max-width: 100%;
        }
      </style>
      <style type="text/css">
        @media only screen and (max-width:480px) {
          table.mj-full-width-mobile {
            width: 100% !important;
          }
    
          td.mj-full-width-mobile {
            width: auto !important;
          }
        }
      </style>
    </head>
    
    <body style="word-spacing:normal;">
      <div style="">
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;">
            <tbody>
              <tr>
                <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                  <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                      <tbody>
                        <tr>
                          <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                              <tbody>
                                <tr>
                                  <td style="width:162px;">
                                    <img height="auto" src="https://firebasestorage.googleapis.com/v0/b/mutika-rt.appspot.com/o/edenred%2Fedenredlogoj.jpg?alt=media&token=32578ec0-9953-4ce2-82e7-96543d4b98a5" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="162" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                            <div style="font-family:helvetica;font-size:24px;font-weight:bold;line-height:1;text-align:center;color:#fb0101;">EVENTO ESCLUSIVO</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div style="margin:0px auto;max-width:600px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
            <tbody>
              <tr>
                <td style="direction:ltr;font-size:0px;padding:0 0;text-align:center;">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                  <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                      <tbody>
                        <tr>
                          <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                              <tbody>
                                <tr>
                                  <td style="width:550px;">
                                    <img height="auto" src="https://firebasestorage.googleapis.com/v0/b/mutika-rt.appspot.com/o/edenred%2Fmilano0611.jpg?alt=media&token=d15c7bb2-1784-44bc-98c1-c9cee9ff2ffa" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;">
            <tbody>
              <tr>
                <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                  <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                  <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                      <tbody>
                        <tr>
                          <td align="justify" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                            <div style="font-family:helvetica;font-size:14px;line-height:1.25;color:#000000;"><p>Ciao <b>${nominativo}</b>,<br><br>
                            desideriamo ricordarti che <b>mercoledì 06 novembre</b> si terrà l’evento <b>Edenred's Club: Questo non è un Party</b> presso <b>Piscina Cozzi</b> – Viale Tunisia, 35 a partire <b>dalle ore 19:00.</b><br><br>
                            Sarà un'occasione unica per incontrarci e condividere insieme questo momento speciale. Di seguito troverai alcune <b>informazioni utili</b> sull’evento:<br><br>
                            <ul>
                              <li><b>AGENDA:</b><br><br>
                                19:00 - 20:30: Arrivo ospiti, light aperitif e visita alla mostra <i><b>Cinquant’anni di tempo libero a Milano. 1930 - 1980</b></i><br>
                                20:30 - 22:00: Cena standing e intrattenimento<br>
                                22:00 - 24:00: Cocktail e Dj set<br><br>
                              </li>
                              <li><b>MEZZI PUBBLICI:</b><br><br>
                                <b>Linea Tram 1 </b>– fermata v.le Tunisia<br>
                                <b>Linea Tram 5 </b>– fermata v.le Tunisia<br>
                                <b>Linea Tram 33 </b>– fermata v.le Tunisia<br>
                                <b>Metro linea M3 Gialla</b> – fermata Repubblica M3<br>
                                <b>Metro linea M2 Verde</b> – fermata Stazione Centrale M2<br>
                                <b>Stazione Ferroviaria </b>– fermata Milano Repubblica linee del servizio suburbano di Milano S1, S2, S5, S6, S12, S13<br><br>
                              </li>
                              <li><b>PARCHEGGI:</b><br><br>
                                Parcheggio a pagamento <a href=${linkManuzio} alt='link parcheggio Manuzio'>Parcheggio Manuzio</a> sito in via Aldo Manuzio, 10 - 100mt dalla Piscina Cozzi<br>
                                Parcheggio a pagamento <a href=${linkMachiavelli} alt='link parcheggio Machiavelli'>Machiavelli Parking</a> sito in via Camillo Finocchiaro Aprile, 1 - 180mt dalla Piscina Cozzi<br><br>
                                In Viale Tunisia sono presenti parcheggi comunali a pagamento evidenziati e segnalati da strisce blu.<br><br>
                              </li>
                            </ul>
                           
                            Per qualsiasi domanda o ulteriore informazione, non esitare a contattare la segreteria organizzativa all’indirizzo e-mail segreteria@edenredclub.it
                            </p>
                            
                                    <p style="text-align:center">Ti aspettiamo,<br><b>
                                    Edenred's Club</b>
                                    <hr>
                                    </p></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--[if mso | IE]></td></tr></table><![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--[if mso | IE]></td></tr></table><![endif]-->
      </div>
    </body>
    
    </html>`;
};
// const mailErrorHtml = `<!doctype html>
//     <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

//     <head>
//       <title>
//       </title>
//       <!--[if !mso]><!-->
//       <meta http-equiv="X-UA-Compatible" content="IE=edge">
//       <!--<![endif]-->
//       <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
//       <meta name="viewport" content="width=device-width, initial-scale=1">
//       <style type="text/css">
//         #outlook a {
//           padding: 0;
//         }

//         body {
//           margin: 0;
//           padding: 0;
//           -webkit-text-size-adjust: 100%;
//           -ms-text-size-adjust: 100%;
//         }

//         table,
//         td {
//           border-collapse: collapse;
//           mso-table-lspace: 0pt;
//           mso-table-rspace: 0pt;
//         }

//         img {
//           border: 0;
//           height: auto;
//           line-height: 100%;
//           outline: none;
//           text-decoration: none;
//           -ms-interpolation-mode: bicubic;
//         }

//         p {
//           display: block;
//           margin: 13px 0;
//         }
//       </style>
//       <!--[if mso]>
//             <noscript>
//             <xml>
//             <o:OfficeDocumentSettings>
//               <o:AllowPNG/>
//               <o:PixelsPerInch>96</o:PixelsPerInch>
//             </o:OfficeDocumentSettings>
//             </xml>
//             </noscript>
//             <![endif]-->
//       <!--[if lte mso 11]>
//             <style type="text/css">
//               .mj-outlook-group-fix { width:100% !important; }
//             </style>
//             <![endif]-->
//       <style type="text/css">
//         @media only screen and (min-width:480px) {
//           .mj-column-per-100 {
//             width: 100% !important;
//             max-width: 100%;
//           }
//         }
//       </style>
//       <style media="screen and (min-width:480px)">
//         .moz-text-html .mj-column-per-100 {
//           width: 100% !important;
//           max-width: 100%;
//         }
//       </style>
//       <style type="text/css">
//         @media only screen and (max-width:480px) {
//           table.mj-full-width-mobile {
//             width: 100% !important;
//           }

//           td.mj-full-width-mobile {
//             width: auto !important;
//           }
//         }
//       </style>
//     </head>

//     <body style="word-spacing:normal;">
//       <div style="">
//         <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
//         <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
//           <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;">
//             <tbody>
//               <tr>
//                 <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
//                   <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
//                   <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
//                     <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
//                       <tbody>
//                         <tr>
//                           <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
//                             <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
//                               <tbody>
//                                 <tr>
//                                   <td style="width:162px;">
//                                     <img height="auto" src="https://firebasestorage.googleapis.com/v0/b/mutika-rt.appspot.com/o/edenred%2Fedenredlogoj.jpg?alt=media&token=32578ec0-9953-4ce2-82e7-96543d4b98a5" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="162" />
//                                   </td>
//                                 </tr>
//                               </tbody>
//                             </table>
//                           </td>
//                         </tr>
//                         <tr>
//                           <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
//                             <div style="font-family:helvetica;font-size:24px;font-weight:bold;line-height:1;text-align:center;color:#fb0101;">EVENTO ESCLUSIVO</div>
//                           </td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>
//                   <!--[if mso | IE]></td></tr></table><![endif]-->
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//                 <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
//           <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;">
//             <tbody>
//               <tr>
//                 <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
//                   <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
//                   <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
//                     <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
//                       <tbody>
//                         <tr>
//                           <td align="justify" style="font-size:0px;padding:10px 25px;word-break:break-word;">
//                             <div style="font-family:helvetica;font-size:14px;line-height:1.25;color:#000000;"><p>Gentile <b>${nominativo}</b>,<br><br>
//                             Desideriamo ricordarti che <b>martedì 18 giugno</b> si terrà l’evento <b>Edenred’s Club</b> presso <b>Palazzo Colonna</b> - <a title="apri in google maps" href="https://www.google.co.uk/maps/place/P.za+dei+Santi+Apostoli,+66,+00187+Roma+RM,+Italia/@41.897166,12.4809245,17z/data=!3m1!4b1!4m6!3m5!1s0x132f604d492482ad:0x4bf477b117640cea!8m2!3d41.897162!4d12.4834994!16s%2Fg%2F11c2dnvq95?entry=ttu" target="_blank" style="color:#fb0101;">Piazza SS. Apostoli, 66 Roma (RM)</a> a partire <b>dalle ore 19:00.</b> <br><br>
//                             Sarà un'occasione unica per incontrarci e condividere insieme questo momento speciale. <br><br> 
//                             Di seguito troverai alcune <b>informazioni utili</b> sull’evento: <br><br>
//                             <ul>
//                             <li><b>AGENDA:</b><br>
//                             19:00 - 20:30: Arrivo ospiti, light aperitif e visita degli appartamenti al piano terra<br>
//                             20:30 - 22:00: Cena standing <br>
//                             22:00 - 24:00: Cocktail in Galleria del Cardinale <br><br>
//                             </li>
//                             <li><b>MEZZI PUBBLICI:</b><br>
//                             Dalla Stazione Termini puoi usufruire di uno dei seguenti autobus:<BR>
//                             <b>Autobus 64</b> – P.za Stazione S. Pietro <br>
//                             <b>Autobus 40</b> – Termini (linea circolare) <br>
//                             <b>Autobus 85</b> – direzione Arco di Travertino <br><br>
//                             scendi alla fermata Plebiscito - raggiungi <b>P.za dei Santi Apostoli 66</b> a 4 minuti a piedi dalla fermata dell’autobus.<br><br>
//                             <u>*per qualsiasi necessità, la location dispone di un secondo accesso in P.za della Pilotta 30.</u><br><br>
//                             </li>
//                             <li><b>PARCHEGGI:</b><br>
//                             In Piazza dei Santi Apostoli e in Via della Pilotta sono presenti parcheggi comunali a pagamento evidenziati e segnalati da strisce blu. <br><br></li>
//                             <li><b>ALTRE INFORMAZIONI UTILI:</b><br>
//                             Al termine dell’evento l’uscita dal Palazzo sarà in Via della Pilotta, 17 Roma (RM). <br></li>
//                             </ul>
//                             <br>
//                             Per qualsiasi domanda o ulteriore informazione, non esitare a contattare la segreteria organizzativa all’indirizzo e-mail segreteria@edenredclub.it
//                             </p>

//                                     <p>Ti aspettiamo,<br><b>
//                                     Edenred's Club</b>
//                                     <hr>
//                                     </p></div>
//                           </td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>
//                   <!--[if mso | IE]></td></tr></table><![endif]-->
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//         <!--[if mso | IE]></td></tr></table><![endif]-->
//       </div>
//         <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
//         <div style="margin:0px auto;max-width:600px;">
//           <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
//             <tbody>
//               <tr>
//                 <td style="direction:ltr;font-size:0px;padding:0 0;text-align:center;">
//                   <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
//                   <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
//                     <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
//                       <tbody>
//                         <tr>
//                           <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
//                             <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
//                               <tbody>
//                                 <tr>
//                                   <td style="width:550px;">
//                                     <img height="auto" src="https://firebasestorage.googleapis.com/v0/b/mutika-rt.appspot.com/o/edenred%2Finvito.jpg?alt=media&token=1a03f716-7939-4275-9004-accac84461c6" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="550" />
//                                   </td>
//                                 </tr>
//                               </tbody>
//                             </table>
//                           </td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>
//                   <!--[if mso | IE]></td></tr></table><![endif]-->
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//         <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
//     </body>

//     </html>`;

export { mailReminderHtml };
